export interface CartItem {
	sku: string;
	priceCents: number;
	image: string;
	name: string;
	quantity: number;
}

class CartModel {
	subtotalPriceCents = 0;

	private cart: CartItem[] = [];
	private taxPriceCents = 0;
	private onChangeCallback: any;

	addToCart(item: CartItem) {
		// Is item already in cart?
		for (let i in this.cart) {
			if (this.cart[i].sku === item.sku) {
				this.cart[i].quantity += item.quantity;
				this.recalculateCart();
				if (this.onChangeCallback) this.onChangeCallback();
				return;
			}
		}

		this.cart.push(item);
		this.recalculateCart();
		if (this.onChangeCallback) this.onChangeCallback();
		localStorage.setItem("cart", JSON.stringify(this.cart));
	}

	getCart() {
		// return this.cart;
		let cart = JSON.parse(<string>localStorage.getItem("cart"));
		if(!cart || cart === '') return this.cart;
		 else return cart;
	}

	deleteItemFromCart(sku: string) {
		this.cart = this.cart.filter((item) => {
			return item.sku !== sku;
		});

		localStorage.setItem('cart', JSON.stringify(this.cart))

		this.recalculateCart();
		if (this.onChangeCallback) this.onChangeCallback();
	}

	recalculateCart() {
		this.subtotalPriceCents = 0;
		for (let item of this.cart) {
			this.subtotalPriceCents += item.priceCents * item.quantity;
		}
	}

	subscribeToChange(callback: any) {
		this.onChangeCallback = callback;
	}
}

let cartModel = new CartModel();
export default cartModel;
