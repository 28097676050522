import * as React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import daviesLogo from '../../images/daviesLogoBlue.png';
import './HomePage.scss';
import Label from '../../components/label/Label';
import HouseCard from '../../components/houseCard/HouseCard';

import data from '../../data.json';

import appRouter from '../../router/appRouter';
// import ImageOverlay from '../../components/imageOverlay/ImageOverlay';
// import home from './Home.png';
import utils from '../../utils/utils';
import { ParadeData } from '../../utils/HomeData';

const paradeData: ParadeData = data as ParadeData;

const HomePage: React.FC = () => {
	function onClickHouse(homeName: string) {
		appRouter.navigate('/home/details', { homeName });
	}

let key:number = 0;
	function renderHouseCards() {
		let houseCards: any = [];

		for (let i in paradeData.houses) {
			let house = paradeData.houses[i];
			houseCards.push(
				<HouseCard
					key={key++}
					name={i}
					houseImage={utils.getImagePath(i, 'exterior', house.exterior.images[0])}
					roomCount={house.stats.bedrooms}
					onClick={() => {
						onClickHouse(i);
					}}
				/>
			);
		}

		return houseCards;
	}

	return (
		<Page className="rsHomePage">
			{/*<ImageOverlay source={home}/>*/}
			<div className="daviesLogo">
				<img alt='' src={daviesLogo} />
			</div>
			<Label allCaps medium center fw500 lightGray>
				Explore Our Homes
			</Label>
			<div className="divider" />
			{renderHouseCards()}
		</Page>
	);
};
export default HomePage;
