// const PARADE_HOME_PATH = 'davies2020';
const PARADE_HOME_PATH = 'https://f001.backblazeb2.com/file/parade-of-homes';

class Utils {
	getImagePath(homeName: string, prefix: string, imageUrl: string): string {
		return PARADE_HOME_PATH + '/' + homeName + '/' + prefix + '/' + imageUrl;
	}

	numberWithCommas(x: number) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
}

let utils = new Utils();
export default utils;
