import * as React from 'react';
import { popupController } from '@bit/redsky.framework.rs.996';
import Popup, { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Label from '../../components/label/Label';
import { RsFormGroup, RsFormControl, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import './ContactUsPopup.scss';
import Input from '../../components/input/Input';
import { RiCloseLine } from 'react-icons/ri';
import CompanyLogo from '../../components/companyLogo/CompanyLogo';

export interface ContactUsPopupProps extends PopupProps {}

interface ContactFormData {
	name: string;
	email: string;
	message: string;
}

const ContactUsPopup: React.FC<ContactUsPopupProps> = (props) => {
	let contactUsFormGroup = new RsFormGroup([
		new RsFormControl('name', '', [
			new RsValidator(RsValidatorEnum.REQ, 'Name is required'),
			new RsValidator(RsValidatorEnum.MIN, 'Must have longer length', 3)
		]),
		new RsFormControl('email', '', [
			new RsValidator(RsValidatorEnum.REQ, 'Email is required'),
			new RsValidator(RsValidatorEnum.EMAIL, 'Invalid email format')
		]),
		new RsFormControl('message', '', [
			new RsValidator(RsValidatorEnum.REQ, 'Message is required'),
			new RsValidator(RsValidatorEnum.MIN, 'Must have longer length', 3)
		])
	]);

	const [form, setForm] = React.useState(contactUsFormGroup);

	function updateControl(control: RsFormControl) {
		form.update(control);
	}

	function onSubmit() {
		if (form.isValid() === false) {
			setForm(form.clone());
			return;
		}

		const formData = form.toModel<ContactFormData>();
		console.log(formData);
		popupController.close(ContactUsPopup);
	}

	function onKeyDown(el: any) {
		if (el.key === 'Enter') {
			onSubmit();
		}
	}

	return (
		<Popup opened={props.opened} popupId="rsContactUsPopup" preventCloseByBackgroundClick>
			<div className="popupFrame">
				<div
					className="closeBtn"
					onClick={() => {
						popupController.close(ContactUsPopup);
					}}
				>
					<RiCloseLine size={25} color="#8A8A8F" />
				</div>
				<Label center medSmall blue>
					We would love to hear from you!
				</Label>

				<div className="contactForm" onKeyDown={onKeyDown}>
					<Input
						id="ContactName"
						inputType="text"
						control={form.get('name')}
						updateControl={updateControl}
						placeholder="Name"
					/>
					<Input
						id="ContactEmail"
						inputType="text"
						control={form.get('email')}
						updateControl={updateControl}
						placeholder="Email"
					/>
					<Input
						id="ContactMessage"
						inputType="textarea"
						control={form.get('message')}
						updateControl={updateControl}
						placeholder="Tell us what you think..."
					/>
				</div>

				<div className="submitBtn" onClick={onSubmit}>
					Submit
				</div>

				<CompanyLogo width={70} />
			</div>
		</Popup>
	);
};
export default ContactUsPopup;
