import React from 'react';
import './Label.scss';

interface LabelProps {
	onClick?: React.MouseEventHandler<HTMLLabelElement> | void;
	children?: React.ReactNode;
	className?: string;
	left?: boolean;
	right?: boolean;
	center?: boolean;
	tiny?: boolean;
	xxSmall?: boolean;
	xSmall?: boolean;
	small?: boolean;
	medSmall?: boolean;
	medium?: boolean;
	medLarge?: boolean;
	large?: boolean;
	xLarge?: boolean;
	xxLarge?: boolean;
	fw100?: boolean;
	fw300?: boolean;
	fw500?: boolean;
	fw700?: boolean;
	fw900?: boolean;
	white?: boolean;
	blue?: boolean;
	black?: boolean;
	red?: boolean;
	gray?: boolean;
	color?: string;
	lightGray?: boolean;
	attentionDescription?: boolean;
	buttonText?: boolean;
	timeText?: boolean;
	completeText?: boolean;
	categoryHeader?: boolean;
	boldButtonText?: boolean;
	tabToEdit?: boolean;
	rightAlignedDescription?: boolean;
	descriptionPrompt?: boolean;
	time?: boolean;
	subtext?: boolean;
	enable?: boolean;
	paragraph?: boolean;
	subScreenHeader?: boolean;
	alternateNavRed?: boolean;
	allCaps?: boolean;
}

function getClasses(props: LabelProps) {
	let classes = 'rsLabel';
	if (props.allCaps) classes += ' allCaps';
	if (props.className) classes += ` ${props.className}`;
	if (props.left) classes += ' left';
	else if (props.right) classes += ' right';
	else if (props.center) classes += ' center';
	if (props.tiny) classes += ' tiny';
	else if (props.xxSmall) classes += ' xxSmall';
	else if (props.xSmall) classes += ' xSmall';
	else if (props.small) classes += ' small';
	else if (props.medSmall) classes += ' medSmall';
	else if (props.medium) classes += ' medium';
	else if (props.medLarge) classes += ' medLarge';
	else if (props.large) classes += ' large';
	else if (props.xLarge) classes += ' xLarge';
	else if (props.xxLarge) classes += ' xxLarge';
	if (props.white) classes += ' white';
	else if (props.blue) classes += ' blue';
	else if (props.black) classes += ' black';
	else if (props.red) classes += ' red';
	else if (props.gray) classes += ' gray';
	else if (props.lightGray) classes += ' lightGray';
	if (props.fw100) classes += ' fw100';
	else if (props.fw300) classes += ' fw300';
	else if (props.fw500) classes += ' fw500';
	else if (props.fw700) classes += ' fw700';
	else if (props.fw900) classes += ' fw900';
	// White text
	else if (props.attentionDescription) classes += ' _white _500 _14';
	else if (props.buttonText) classes += ' _white _500 _10';
	else if (props.timeText) classes += ' _white _500 _12';
	else if (props.completeText) classes += ' _white _500 _7';
	else if (props.categoryHeader) classes += ' _white _700 _20';
	else if (props.boldButtonText) classes += ' _white _700 _15';
	// Lightgray text
	else if (props.tabToEdit) classes += ' _lightgray _500 _12';
	// Gray text
	else if (props.rightAlignedDescription) classes += ' _gray _500 _16';
	else if (props.descriptionPrompt) classes += ' _gray _700 _12';
	// Dimgray text
	else if (props.time) classes += ' _dimgray _300 _12';
	else if (props.subtext) classes += ' _dimgray _300 _15';
	else if (props.enable) classes += ' _dimgray _500 _8';
	else if (props.paragraph) classes += ' _dimgray _700 _18';
	else if (props.subScreenHeader) classes += ' _dimgray _700 _20';
	// Red text
	else if (props.alternateNavRed) classes += ' _red _700 _13';

	return classes;
}

function labelType(props: LabelProps) {
	return (
		<div
			onClick={props.onClick as React.MouseEventHandler}
			style={props.color ? { color: props.color } : {}}
			className={getClasses(props)}
		>
			{props.children}
		</div>
	);
}

const Label: React.FC<LabelProps> = props => {
	return <>{labelType(props)} </>;
};

export default Label;