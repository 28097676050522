import * as React from 'react';
import RsInput from '@bit/redsky.framework.rs.input';
import './Input.scss';
import { RsFormControl } from '@bit/redsky.framework.rs.form';

interface InputProps {
	id: string;
	inputType: 'text' | 'password' | 'textarea';
	label?: string;
	placeholder?: string;
	className?: string;
	labelClass?: string;
	inputClass?: string;
	control?: RsFormControl;
	updateControl?: (control: RsFormControl) => void;
}

const Input: React.FC<InputProps> = (props) => {
	function getLabelClasses() {
		let classes = 'inputLabel';
		if (props.className) classes += ` ${props.className}`;
		return classes;
	}

	function getInputClasses() {
		let classes = 'input';
		if (props.inputClass) classes += ` ${props.inputClass}`;
		return classes;
	}

	function renderLabel() {
		if (!props.label) return;
		return (
			<div className="alignLabelLeft">
				<label className={getLabelClasses()}>{props.label}</label>
			</div>
		);
	}

	return (
		<div className="rsInput">
			{renderLabel()}
			<RsInput
				placeholder={props.placeholder}
				type={props.inputType}
				name={props.id}
				className={getInputClasses()}
				control={props.control || new RsFormControl(props.id, '', [])}
				updateControl={props.updateControl}
			/>
		</div>
	);
};

export default Input;
