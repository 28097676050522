import * as React from 'react';
import Popup, { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Label from '../../components/label/Label';
import './EmptyCartPopup.scss';
import { RiCloseLine } from 'react-icons/ri'
import { popupController } from '@bit/redsky.framework.rs.996/dist';

export interface EmptyCartPopupProps extends PopupProps {}

const EmptyCartPopup: React.FC<EmptyCartPopupProps> = (props) => {
	return (
		<Popup opened={props.opened} popupId={'rsEmptyCartPopup'} preventCloseByBackgroundClick>
			<div id="popUpFrame">
				<Label xLarge fw700 color={'#323232'}>
					Your cart is empty!
				</Label>
				<div className="closeBtn" onClick={()=> {
					popupController.close(EmptyCartPopup);
				}}><RiCloseLine size={30} /></div>
			</div>
		</Popup>
	);
};

export default EmptyCartPopup;
