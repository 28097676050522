import * as React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import './ShopAllPage.scss';
import ProductCard from '../../components/productCard/ProductCard';
import Label from '../../components/label/Label';
import appRouter from '../../router/appRouter';
import { BsArrowLeftShort } from 'react-icons/bs';


import bluePillow from '../../images/blue-throw-pillow.png';
import curvedChair from '../../images/curved-back-chair.png';
import { ProductData } from '../../utils/HomeData';
import data from '../../data.json';
import utils from '../../utils/utils';
import { stringify } from 'querystring';


const productData: { [key: string]: ProductData } = data.products;


export interface ShopAllPageProps {}

const ShopAllPage: React.FC<ShopAllPageProps> = (props) => {

	function renderAllProducts() {
		let products = [];

		let x = 1;
		for(let i in productData) {
			let product = productData[i];
			products.push(
				<ProductCard
					key={x}
					sku={`${product}`}
					name={product.name}
					image={utils.getImagePath('products', i, product.images[0])}
					priceCents={product.priceCents}
					onClick={() => {
						appRouter.navigate('/item/details', { sku: i});
					}}/>)
			x++;
		}
		return products;
	}

	return (
		<Page className="rsShopAllPage">
			<Label className='pageHeader' lightGray medium fw500 onClick={() => appRouter.back('')}>
				<BsArrowLeftShort className="backButton" size={28}/>
				Products
			</Label>
			<div id="AllProductsContainer">
				{renderAllProducts()}
			</div>
		</Page>
	);
};
export default ShopAllPage;
