import * as React from 'react';
import './HouseCard.scss';
import Label from '../label/Label';
import { BsArrowRight } from 'react-icons/bs';

export interface HouseCardProps {
	name: string;
	houseImage: string;
	roomCount: number;
	onClick?: () => void;
}

const HouseCard: React.FC<HouseCardProps> = (props) => {
	return (
		<div className="houseCard">
			<img src={props.houseImage} alt="" />
			<div
				className="banner"
				onClick={() => {
					if (props.onClick) props.onClick();
				}}
			>
				<div>
					<Label allCaps medSmall>
						{props.name}
					</Label>
					<Label xxSmall>{props.roomCount} rooms</Label>
				</div>
				<Label xxSmall allCaps>
					Browse Home
					<BsArrowRight className="rightArrow" />
				</Label>
			</div>
		</div>
	);
};
export default HouseCard;
