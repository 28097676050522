import * as React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import appRouter from '../../router/appRouter';
import { BsArrowLeftShort } from 'react-icons/bs';
import Label from '../../components/label/Label';
import './BrowseRoomsPage.scss';
import FloorPlanIcon from '../../images/floorPlanIcon.png';
import { popupController } from '@bit/redsky.framework.rs.996/dist';
import FloorPlanPopup, { FloorPlanPopupProps } from '../../popups/floorPlanPopup/FloorPlanPopup';
import { BsArrowRight } from 'react-icons/bs';
import data from '../../data.json';
import { ParadeData } from '../../utils/HomeData';
import utils from '../../utils/utils';


const paradeData: ParadeData = data as ParadeData;

export interface BrowseRoomsPageProps {
	homeName: string;
}

export interface RoomCardProps {
	roomName: string;
	imageUrl: string;
	onClick: () => void;
}

const RoomCard: React.FC<RoomCardProps> = (props) => {
	return (
		<div className="cardWrapper snapScrollChild">
			<img alt='' src={props.imageUrl} />
			<div className="exploreButton" onClick={props.onClick}>
				EXPLORE ROOM
				<BsArrowRight size={25} color="#252525"/>
			</div>
			<Label className="roomName" fw500 medium>
				{props.roomName}
			</Label>
		</div>
	);
};

const BrowseRoomsPage: React.FC<BrowseRoomsPageProps> = (props) => {
	let rooms = paradeData.houses[props.homeName].rooms;

	function renderRoomCards() {
		let roomCards:any = [];

			for(let i = 0; i < rooms.length; i++) {
				roomCards.push(
					<RoomCard
						key={i}
					roomName={rooms[i].name}
					imageUrl={utils.getImagePath(props.homeName, 'rooms', rooms[i].images[0])}
					onClick={() => {
						appRouter.navigate('/room/details', {room: rooms[i], homeName: props.homeName});
					}}
				/>)
			}

		return roomCards
	}

	return (
		<Page className="rsBrowseRoomsPage">
			<Label className="navTitle" medium fw500 onClick={() => appRouter.back('')}>
				<BsArrowLeftShort className="backButton" size={28}/>
				Rooms
			</Label>
			<Label
				small
				className="floorPlan"
				onClick={() => popupController.open<FloorPlanPopupProps>(FloorPlanPopup, {homeName: props.homeName})}
			>
				<img alt='' src={FloorPlanIcon} />
				Floor Plan
			</Label>
			<div className="roomList snapScrollParent">
				{renderRoomCards()}
			</div>
		</Page>
	);
};
export default BrowseRoomsPage;
