import * as React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import './ScanPage.scss';
import Label from '../../components/label/Label';
import QrScanner from 'qr-scanner';
import { useEffect } from 'react';
import appRouter from '../../router/appRouter';

//QrScanner.WORKER_PATH = 'qr-scanner-worker.min.js';

const ScanPage: React.FC = () => {
	useEffect(() => {
		let qrScanner: QrScanner | null = null;
		appRouter.subscribeToActiveViewChanged((newView, previousView) => {
			if (newView === 'scan') {
				let videoElement = document.getElementById('QrScannerVideo') as HTMLVideoElement;
				qrScanner = new QrScanner(videoElement, (result: any) => console.log('decoded qr code:', result));
				qrScanner.start().catch(console.error);
			} else {
				if (qrScanner) {
					qrScanner.destroy();
					qrScanner = null;
				}
			}
		});
	}, []);

	return (
		<Page className="rsScanPage">
			<Label allCaps medium center fw500 lightGray>
				Scan Page
			</Label>
			<video id="QrScannerVideo"></video>
		</Page>
	);
};

export default ScanPage;
