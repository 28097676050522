import React from 'react';
import './App.scss';

import { View, popupController } from '@bit/redsky.framework.rs.996';
import appRouter from './router/appRouter';
import routes from './router/routes';
import SplashPage from './pages/splashPage/SplashPage';
import BottomTabBar from './components/bottomTabBar/BottomTabBar';
import CartPage from './pages/cartPage/CartPage';
import ScanPage from './pages/scanPage/ScanPage';

appRouter.addRoutes(routes);

let innerWidth:number = window.innerHeight;

let inlineStyleObj = {
	height: (window.innerHeight - 50),
	overflow: 'auto'
};


function App() {
	return (
		<div id="App" style={innerWidth < 813 ? inlineStyleObj : {}}>
			<View id="home" default={true}>
				<SplashPage />
			</View>
			<View id="cart">
				<CartPage />
			</View>
			<View id="scan">
				<ScanPage />
			</View>

			<BottomTabBar/>
			{popupController.instance}
		</div>
	);
}

export default App;
