import * as React from 'react';
import './ProductCard.scss';
import Label from '../label/Label';
import { StringUtils } from '@bit/redsky.framework.rs.utils';

export interface ProductCardProps {
	sku: string;
	name: string;
	image: string;
	priceCents: number;
	snapScroll?: boolean;
	onClick?: () => void;
}

const ProductCard: React.FC<ProductCardProps> = (props) => {
	let classes = 'productCard';
	if (props.snapScroll) classes += ' snapScrollChild';
	return (
		<div
			className={classes}
			onClick={() => {
				if (props.onClick) props.onClick();
			}}
		>
			<img src={props.image} alt="" />
			<Label className="productName" xSmall fw500 lightGray>
				{props.name}
			</Label>
			<Label className="productPrice" xxSmall lightGray>
				${StringUtils.formatMoney(props.priceCents)}
			</Label>
		</div>
	);
};

export default ProductCard;
