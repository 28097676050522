import * as React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import { useEffect } from 'react';
import appRouter from '../../router/appRouter';
import './SplashPage.scss';
import daviesLogo from '../../images/daviesLogo.png';
import Label from '../../components/label/Label';

const SplashPage: React.FC = () => {

	useEffect(() => {
		setTimeout(() => {
			let path = window.location.pathname;
			if (path === '' || path === '/') {
				appRouter.navigate('/home');
				return;
			}

			if (appRouter.hasRoute(path)) appRouter.navigate(path);
			else appRouter.navigate('/home');
		}, 1500);
	}, []);

	return (
		<Page className="rsSplashPage" disableBackSwipe>
			<div className="fullScreenDim" />
			<div className="daviesLogo">
				<img alt='' src={daviesLogo} />
				<Label allCaps fw900 center xxLarge white>Maven Design</Label>
			</div>
		</Page>
	);
};
export default SplashPage;
