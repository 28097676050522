import * as React from 'react';
import { popupController } from '@bit/redsky.framework.rs.996';
import Popup, { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import './CheckoutPopup.scss';
import { BsArrowLeftShort } from 'react-icons/bs';
import Label from '../../components/label/Label';
import { StringUtils } from '@bit/redsky.framework.rs.utils';

import { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import cartModel from '../../models/cartModel';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
const promise = loadStripe(
	'pk_test_51H5zPnHjTLiWJbgRQl4RlJ7H5KNJAXuYySukRq4C0cQfWV20xVUJFO3boOeMoI8Aq8nPDarMczFaSGQVPcb32EpP00H4q8nGkR'
);

const CheckoutForm: React.FC<any> = (props) => {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [processing, setProcessing] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [clientSecret, setClientSecret] = useState('');
	const [name, setName] = useState('');
	const [address1, setAddress1] = useState('');
	const [address2, setAddress2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [email, setEmail] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [totalPriceCents, setTotalPriceCents] = useState(0);
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		// Create PaymentIntent as soon as the page loads
		window
			.fetch('https://paradeofhomes.app:4242/create-payment-intent', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ items: cartModel.getCart() })
			})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setClientSecret(data.clientSecret);
				setTotalPriceCents(data.orderTotal);
			});
	}, []);

	const cardStyle = {
		style: {
			base: {
				color: '#32325d',
				fontFamily: 'Arial, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#32325d'
				}
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a'
			}
		}
	};

	const handleChange = async (event: any) => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : '');
	};

	const handleSubmit = async (ev: any) => {
		ev.preventDefault();
		setProcessing(true);
		//@ts-ignore
		const payload = await stripe.confirmCardPayment(clientSecret, {
			receipt_email: email,
			payment_method: {
				card: (elements as any).getElement(CardElement),
				billing_details: {
					name: ev.target.name.value
				}
			},
			shipping: {
				name: name,
				address: {
					line1: address1,
					line2: address2,
					city: city,
					state: state,
					postal_code: zipCode
				}
			}
		});
		if (payload.error) {
			setError(`Payment failed ${payload.error.message}`);
			setProcessing(false);
		} else {
			setError(null);
			setProcessing(false);
			setSucceeded(true);
		}
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<Label>Email</Label>
			<input
				type="text"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder="Enter email address"
			/>
			<Label>Name</Label>
			<input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="John Doe" />
			<Label>Shipping Address</Label>
			<input
				type="text"
				value={address1}
				onChange={(e) => setAddress1(e.target.value)}
				placeholder="123 Elm St."
			/>
			<input
				type="text"
				value={address2}
				onChange={(e) => setAddress2(e.target.value)}
				placeholder="Apartment #112."
			/>
			<input type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Salt Lake City" />
			<input type="text" value={state} onChange={(e) => setState(e.target.value)} placeholder="UT" />
			<input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder="ZIP" />
			<Label className="checkoutTotal" xxLarge fw700>${StringUtils.formatMoney(totalPriceCents)}</Label>
			<CardElement id="card-element" options={cardStyle} onChange={handleChange} />
			<button disabled={processing || disabled || succeeded} id="submit">
				<span id="button-text">{processing ? <div className="spinner" id="spinner"></div> : 'Pay'}</span>
			</button>
			{/* Show any error that happens when processing the payment */}
			{error && (
				<div className="card-error" role="alert">
					{error}
				</div>
			)}
			{/* Show a success message upon completion */}
			<p className={succeeded ? 'result-message' : 'result-message hidden'}>
				Payment is successful!
				{localStorage.clear()}
			</p>
		</form>
	);
};

export interface CheckoutPopupProps extends PopupProps {}

const CheckoutPopup: React.FC<CheckoutPopupProps> = (props) => {
	return (
		<Popup opened={props.opened} popupId="rsCheckoutPopup" preventCloseByBackgroundClick>
			<div className="popupFrame">
				<div
					className="backBtn"
					onClick={() => {
						popupController.close(CheckoutPopup);
					}}
				>
					<BsArrowLeftShort size={25} color="#8A8A8F" />
					Cancel
				</div>
				<Elements stripe={promise}>
					<CheckoutForm />
				</Elements>
			</div>
		</Popup>
	);
};
export default CheckoutPopup;
