import * as React from 'react';
import Tabs from '@bit/redsky.framework.rs.996/dist/tabs/Tabs';
import Tab from '@bit/redsky.framework.rs.996/dist/tab/Tab';
import './BottomTabBar.scss';
import { useEffect, useState } from 'react';
import appRouter from '../../router/appRouter';
import './icons/style.css';

type PossibleTabs = 'home' | 'cart' | 'scan';

export interface TabIconProps {
	color: string | null;
	size: number | null;
	className?: string;
}

const TabIcon: React.FC<TabIconProps> = (props) => {
	let styleObj: any = {};
	if (props.color) styleObj.color = props.color;
	if (props.size) styleObj.fontSize = props.size;

	return <div className={props.className} style={styleObj}></div>;
};

const BottomTabBar: React.FC<any> = () => {
	const [selectedTab, setSelectedTab] = useState<PossibleTabs>('home');
	useEffect(() => {
		let subscribeId = appRouter.subscribeToActiveViewChanged((newView: string, previousView: string | null) => {
			setSelectedTab(newView as PossibleTabs);
		});
		return () => {
			appRouter.unsubscribeToActiveViewChanged(subscribeId);
		};
	}, []);

	const tabColor = '#8A8A8F';
	const selectedTabColor = '#29455f';
	return (
		<div className="rsTabBar">
			<Tabs>
				<Tab view="home">
					<TabIcon
						className="icon-home"
						color={selectedTab === 'home' ? selectedTabColor : tabColor}
						size={26}
					/>
				</Tab>
				{/*<Tab view="cart">*/}
				{/*	<TabIcon*/}
				{/*		className="icon-shop"*/}
				{/*		color={selectedTab === 'cart' ? selectedTabColor : tabColor}*/}
				{/*		size={26}*/}
				{/*	/>*/}
				{/*</Tab>*/}
				{/*<Tab view="scan">*/}
				{/*	<TabIcon*/}
				{/*		className="icon-scan"*/}
				{/*		color={selectedTab === 'scan' ? selectedTabColor : tabColor}*/}
				{/*		size={26}*/}
				{/*	/>*/}
				{/*</Tab>*/}
			</Tabs>
		</div>
	);
};
export default BottomTabBar;
