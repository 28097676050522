import * as React from 'react';
import daviesLogoTransparent from '../../images/daviesLogo.png';
import daviesLogo from '../../images/daviesLogoBlue.png';
import './CompanyLogo.scss';

export interface CompanyLogoProps {
	transparent?: boolean;
	width? : number;
}

const CompanyLogo: React.FC<CompanyLogoProps> = (props) => {
	return (
		<div className="companyLogo">
			<img src={props.transparent ? daviesLogoTransparent : daviesLogo} alt="" style={ { width: props.width ? props.width : '' }}/>
		</div>
	);
};

export default CompanyLogo;
