import * as React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import './CartPage.scss';
import Label from '../../components/label/Label';
import CompanyLogo from '../../components/companyLogo/CompanyLogo';
import cartModel, { CartItem as CartItemInterface } from '../../models/cartModel';
import { ReactNode, useEffect, useState } from 'react';
import { StringUtils } from '@bit/redsky.framework.rs.utils';
import appRouter from '../../router/appRouter';
import { popupController } from '@bit/redsky.framework.rs.996/dist';
import CheckoutPopup, { CheckoutPopupProps } from '../../popups/checkoutPopup/CheckoutPopup';
import EmptyCartPopup from '../../popups/emptyCartPopup/EmptyCartPopup';

const TAX_RATE = 0.075; // 7.5% TODO: calculate based on shipping address

export interface CartItemProps extends CartItemInterface {}

const CartItem: React.FC<CartItemProps> = (props) => {
	return (
		<div className="cartItem">
			<img
				src={props.image}
				alt=""
				onClick={() => {
					appRouter.setActiveView('home');
					appRouter.navigate('/item/details', { sku: props.sku });
				}}
			/>
			<div className="itemDetails">
				<h3>{props.name}</h3>
				<div className="itemPrice">${props.priceCents}</div>
				<div className="itemQty">Qty: {props.quantity}</div>
			</div>
			<div
				className="deleteBtn"
				onClick={() => {
					cartModel.deleteItemFromCart(props.sku);
				}}
			>
				Delete
			</div>
		</div>
	);
};

//create your forceUpdate hook
function useForceUpdate() {
	const [, setValue] = useState(0); // integer state
	return () => setValue((value) => ++value); // update the state to force render
}

const CartPage: React.FC = () => {
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		cartModel.subscribeToChange(() => {
			forceUpdate();
		});
	}, []);

	function renderCart() {
		let cart = cartModel.getCart();
		let cartItems: ReactNode[] = [];
		if (cart.length === 0) {
			return (
				<div className="emptyCart">
					<Label fw500 xLarge lightGray>
						Your cart is empty...
					</Label>
				</div>
			);
		}
		let calculatedPrice = 0;
		let key = 0;
		for (let item of cart) {
			calculatedPrice += item.priceCents;
			cartItems.push(
				<CartItem
					key={key++}
					name={item.name}
					image={item.image}
					priceCents={parseFloat((item.priceCents / 100).toFixed(2))}
					quantity={item.quantity}
					sku={item.sku}
				/>
			);
		}

		return cartItems;
	}

	function checkout() {
		let cart = cartModel.getCart();

		if (cart.length === 0) popupController.open(EmptyCartPopup);
		else popupController.open<CheckoutPopupProps>(CheckoutPopup, {});
	}

	let taxAmount = cartModel.subtotalPriceCents * TAX_RATE;
	let total = cartModel.subtotalPriceCents + taxAmount;
	return (
		<Page className="rsCartPage">
			<CompanyLogo width={50} />
			<Label allCaps medium center fw500 lightGray>
				Shopping Cart
			</Label>
			<div id="CartItemWrapper">{renderCart()}</div>
			<div id="CartTotalSectionWrapper">
				<div id="CartTotalSection">
					<div className="taxes">
						<div>Taxes</div>
						<div>${StringUtils.formatMoney(taxAmount)}</div>
					</div>
					<div className="subTotal">
						<div>Subtotal</div>
						<div>${StringUtils.formatMoney(cartModel.subtotalPriceCents)}</div>
					</div>
					<div className="dividingLine"></div>
					<div className="total">
						<div>Total</div>
						<div>${StringUtils.formatMoney(total)}</div>
					</div>
					<div id="CheckOutBtn" onClick={checkout}>
						CHECKOUT
					</div>
				</div>
			</div>
		</Page>
	);
};
export default CartPage;
