import * as React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import appRouter from '../../router/appRouter';
import { BsArrowLeftShort } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import Carousel from 'nuka-carousel';
import bluePillow from '../../images/blue-throw-pillow.png';
import './ItemDetailsPage.scss';
import Label from '../../components/label/Label';
import cartModel from '../../models/cartModel';
import data from '../../data.json';
import { ProductData } from '../../utils/HomeData';
import utils from '../../utils/utils';
import { StringUtils } from '@bit/redsky.framework.rs.utils';
const productData: { [key: string]: ProductData } = data.products;
export interface ItemDetailsPageProps {
	className: string;
	sku: string;
}

const ItemDetailsPage: React.FC<ItemDetailsPageProps> = (props) => {
	const selectValue = React.createRef<HTMLSelectElement>();
	let sku = props.sku;
	let productInfo = productData[sku];
	const sliderControlsConfig = {
		nextButtonText: '>',
		prevButtonText: '<',
		pagingDotsStyle: {
			fill: '#ffffff'
		}
	};

	function renderImageSlider() {
		function getProductImages() {
			let images = [];
			for (let i = 0; i < productInfo.images.length; i++) {
				images.push(
					<img
						key={i}
						src={utils.getImagePath('products', props.sku, productInfo.images[i])}
						alt={props.sku}
					/>
				);
			}
			return images;
		}

		return (
			<Carousel height="300px" defaultControlsConfig={sliderControlsConfig} className="itemSlider">
				{getProductImages()}
			</Carousel>
		);
	}

	function getQuantity() {
		if (!selectValue.current) return 0;
		return parseInt(selectValue.current.value);
	}
	let key = 0;
	return (
		<Page className="rsItemDetailsPage">
			<BsArrowLeftShort size={28} className="backButton" onClick={() => appRouter.back('')} />
			{renderImageSlider()}
			<div className="content">
				<Label large gray fw500>
					{productInfo.name}
				</Label>
				<Label medLarge className="price">
					${StringUtils.formatMoney(productInfo.priceCents)}
				</Label>
				<div className="addToCart">
					<div
						className="addToCartButton"
						onClick={() => {
							let itemQty: number = getQuantity();
							cartModel.addToCart({
								sku: sku,
								priceCents: productInfo.priceCents,
								image: utils.getImagePath('products', sku, productInfo.images[0]),
								name: productInfo.name,
								quantity: itemQty
							});
							appRouter.setActiveView('cart');
						}}
					>
						Add To Cart
					</div>
					<div>
						<Label className="quantity" lightGray medLarge allCaps fw500>
							QTY
						</Label>
						<Label className="quantity flexCenter" lightGray medLarge allCaps fw500>
							<select ref={selectValue}>
								{[...Array(20).keys()].map((i) => {
									return <option key={key++}>{i + 1}</option>;
								})}
							</select>
							<IoIosArrowDown className="downArrow" size={20} />
						</Label>
					</div>
				</div>
				<div>
					{productInfo.description}
				</div>
			</div>
		</Page>
	);
};
export default ItemDetailsPage;
