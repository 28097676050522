import HomePage from '../pages/homePage/HomePage';
import HomeDetailsPage from '../pages/homeDetailsPage/HomeDetailsPage';
import ExteriorDetailsPage from '../pages/exteriorDetailsPage/ExteriorDetailsPage';
import BrowseRoomsPage from '../pages/browseRoomsPage/BrowseRoomsPage';
import RoomDetailsPage from '../pages/roomDetailsPage/RoomDetailsPage';
import ItemDetailsPage from '../pages/itemDetailsPage/ItemDetailsPage';
import ShopAllPage from '../pages/shopAllPage/ShopAllPage';

let routes = {
	'/home': HomePage,
	'/home/details': HomeDetailsPage,
	'/exterior/details' : ExteriorDetailsPage,
	'/browse/rooms' : BrowseRoomsPage,
	'/room/details': RoomDetailsPage,
	'/item/details' : ItemDetailsPage,
	'/shop/all': ShopAllPage
};

export default routes;
