import * as React from 'react';
import Label from '../../components/label/Label';
import { Page } from '@bit/redsky.framework.rs.996';
import './RoomDetailsPage.scss';
import { BsArrowLeftShort } from 'react-icons/bs';
import { RiShoppingBagLine } from 'react-icons/ri';
import appRouter from '../../router/appRouter';
import Carousel from 'nuka-carousel';
import room1 from '../../images/room1.jpg';
import room2 from '../../images/room2.jpg';
import daviesLogo from '../../images/daviesLogoBlue.png';
import { popupController } from '@bit/redsky.framework.rs.996/dist';
import ContactUsPopup, { ContactUsPopupProps } from '../../popups/contactUsPopup/ContactUsPopup';
import { IoIosArrowUp } from 'react-icons/io';
import { useState } from 'react';

import bluePillow from '../../images/blue-throw-pillow.png';
import curvedChair from '../../images/curved-back-chair.png';

import data from '../../data.json';
import { ProductData } from '../../utils/HomeData';
import utils from '../../utils/utils';
import ProductCard from '../../components/productCard/ProductCard';

const productData: { [key: string]: ProductData } = data.products;

export interface RoomDetailsPageProps {
	className: string;
	homeName: string;
	room: {
		description: string;
		designer: { name: string; image: string };
		images: string[];
		name: string;
		productSkus: string[];
		video: string;
	};
}

const RoomDetailsPage: React.FC<RoomDetailsPageProps> = (props) => {
	const [showProducts, setShowProducts] = useState<boolean>(false);

	let roomData = props.room;
	let homeName = props.homeName;

	const sliderControlsConfig = {
		nextButtonText: '>',
		prevButtonText: '<',
		pagingDotsStyle: {
			fill: '#ffffff'
		},
		pagingDotsClassName: 'josh'
	};

	function renderImageSlider() {
		function getRoomImages() {
			let images: any = [];

			for (let i = 0; i < roomData.images.length; i++) {
				let image = roomData.images[i];
				images.push(
					<img className="sliderImg" key={i} alt={image} src={utils.getImagePath(homeName, 'rooms', image)} />
				);
			}
			return images;
		}

		return (
			<Carousel style={{ height: '550px' }} defaultControlsConfig={sliderControlsConfig}>
				{getRoomImages()}
			</Carousel>
		);
	}

	function renderProductsForRoom() {
		let products: any = [];

		for (let i = 0; i < roomData.productSkus.length; i++) {
			let productSku: string = roomData.productSkus[i];
			let product = productData[productSku];

			products.push(
				<ProductCard
					key={i}
					snapScroll
					sku={productSku}
					name={product.name}
					image={utils.getImagePath('products', productSku ,product.images[0])}
					priceCents={product.priceCents}
					onClick={() => {
						appRouter.navigate('/item/details', { sku: productSku });
					}}
				/>
			);
		}

		return products;
	}

	function renderProducts() {
		return (
			<div
				className="productView"
				onClick={() => {
					setShowProducts(false);
				}}
			>
				<div className="productCarousel">
					<div className="header">
						<Label allCaps medSmall>
							PRODUCTS
						</Label>
						{/*<Label*/}
						{/*	allCaps*/}
						{/*	xSmall*/}
						{/*	onClick={() => {*/}
						{/*		appRouter.navigate('/shop/all');*/}
						{/*	}}*/}
						{/*>*/}
						{/*	SHOW ALL*/}
						{/*</Label>*/}
					</div>
					<div className="productContainer snapScrollParent">
						{renderProductsForRoom()}
					</div>
				</div>
			</div>
		);
	}

	return (
		<Page className="rsRoomDetailsPage">
			<div className="navHeader" onClick={() => appRouter.back('')}>
				<BsArrowLeftShort size={25} className="backButton" />
				{roomData.name}
			</div>
			{renderImageSlider()}
			<div className="content">
				{roomData.video ? (
					<video controls className="designerRoomIntroVideo">
						<source src={utils.getImagePath(homeName, 'video', roomData.video)} type="video/mp4" />
					</video>
				) : (
					''
				)}
				<Label allCaps medLarge fw500 large color="#444444">
					About this room
				</Label>
				<Label xSmall className="designTeamHeader">
					FROM THE DESIGN TEAM
				</Label>
				<Label>{roomData.description}</Label>
			</div>
			<div className="designerCallout">
				<div className="avatarPhoto">
					<img alt="" src={utils.getImagePath(homeName, 'designer', roomData.designer.image)}></img>
				</div>
				<div>
					<Label large>{roomData.designer.name}</Label>
					<Label allCaps fw700 xSmall className="designerLabel">
						Designer
					</Label>
				</div>
			</div>
			<div
				className="shopThisRoom"
				onClick={() => {
					setShowProducts(true);
				}}
			>
				<RiShoppingBagLine size={20} color="white" />
				SHOP THIS ROOM
				<IoIosArrowUp className="upArrow" size={20} />
			</div>
			<div className="daviesLogo">
				<img alt="" src={daviesLogo} />
				<Label xSmall>Building a Home?</Label>
				<Label
					xSmall
					className="learnMoreLink"
					onClick={() => {
						popupController.open<ContactUsPopupProps>(ContactUsPopup);
					}}
				>
					Learn More
				</Label>
			</div>
			{showProducts ? renderProducts() : null}
		</Page>
	);
};
export default RoomDetailsPage;
