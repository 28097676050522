import * as React from 'react';
import { popupController } from '@bit/redsky.framework.rs.996';
import Popup, { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import './FloorPlanPopup.scss';
import { BsArrowLeftShort } from 'react-icons/bs';
import data from '../../data.json';
import Label from '../../components/label/Label';

import floorPlanImage1 from '../../images/floorPlanLevel1.png';
import floorPlanImage2 from '../../images/floorPlanLevel2.png';
import { ParadeData } from '../../utils/HomeData';
import utils from '../../utils/utils';

const paradeData: ParadeData = data as ParadeData;

export interface FloorPlanPopupProps extends PopupProps {
	homeName: string;
}

// :TODO Make Floor plan have dynamic images when clicked on.

const FloorPlanPopup: React.FC<FloorPlanPopupProps> = (props) => {
	function renderFloorPlanImages() {
		let floorPlans = [];

		let house = paradeData.houses[props.homeName];

		for (let i = 0; i < house.stats.floorPlan.length; i++) {
			floorPlans.push(<img alt={house.stats.floorPlan[i]} src={utils.getImagePath(props.homeName, 'floorPlan', house.stats.floorPlan[i])} />);
		}

		return floorPlans;
	}

	return (
		<Popup opened={props.opened} popupId="rsFloorPlanPopup" preventCloseByBackgroundClick>
			<div className="popupFrame">
				<div
					className="backBtn"
					onClick={() => {
						popupController.close(FloorPlanPopup);
					}}
				>
					<BsArrowLeftShort size={25} color="#8A8A8F" />
					Floor Plan
				</div>

				{renderFloorPlanImages()}

				{/*<Label medSmall fw500>Main Level</Label>*/}
				{/*<Label xxSmall>6500 sq ft</Label>*/}
				{/*<img alt='' src={floorPlanImage1} />*/}
				{/*<Label medSmall fw500>Upstairs</Label>*/}
				{/*<Label xxSmall>4500 sq ft</Label>*/}
				{/*<img alt='' src={floorPlanImage2} />*/}
			</div>
		</Popup>
	);
};
export default FloorPlanPopup;
