import * as React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import Label from '../../components/label/Label';
import './ExteriorDetailsPage.scss';
import data from '../../data.json';
import { ParadeData } from '../../utils/HomeData';
import Carousel from 'nuka-carousel';
import appRouter from '../../router/appRouter';
import { BsArrowLeftShort } from 'react-icons/bs';
import utils from '../../utils/utils';

const paradeData: ParadeData = data as ParadeData;

export interface ExteriorDetailsPageProps {
	homeName: string;
}

const ExteriorDetailsPage: React.FC<ExteriorDetailsPageProps> = (props) => {
	let home = paradeData.houses[props.homeName];

	const sliderControlsConfig = {
		nextButtonText: '>',
		prevButtonText: '<',
		pagingDotsStyle: {
			fill: '#ffffff'
		}
	};

	function renderImageSlider() {
		function renderImages() {
			let images: any = [];

			for (let i = 0; i < home.exterior.images.length; i++) {
				images.push(
					<img
						alt=''
						className="sliderImg"
						key={i}
						src={utils.getImagePath(props.homeName, 'exterior', home.exterior.images[i])}
					/>
				);
			}
			return images;
		}

		return (
			<Carousel
				className="exteriorSlider"
				defaultControlsConfig={sliderControlsConfig}
				height={'300px'}
				heightMode={'max'}
			>
				{renderImages()}
			</Carousel>
		);
	}

	return (
		<Page className="rsExteriorDetailsPage">
			<BsArrowLeftShort className="backButton" onClick={() => appRouter.back('')} />
			{renderImageSlider()}
			<div className="content">
				<Label medium fw500 className="exteriorTitle">
					Exterior Details
				</Label>
				<Label className="exteriorDetails">{home.exterior.description}</Label>
			</div>
		</Page>
	);
};
export default ExteriorDetailsPage;
