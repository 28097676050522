import * as React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import './HomeDetailsPage.scss';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import appRouter from '../../router/appRouter';
import { FaBath, FaBed, FaTape } from "react-icons/fa";
import Label from '../../components/label/Label';
import BathIcon from './BathIcon.svg';
import RoomIcon from './RoomIcon.svg';
import MeasureIcon from './MeasureIcon.svg';
import FloorPlanIcon from '../../images/floorPlanIcon.png';
import daviesLogo from '../../images/daviesLogoBlue.png';
import { popupController } from '@bit/redsky.framework.rs.996/dist';
import ContactUsPopup, { ContactUsPopupProps } from '../../popups/contactUsPopup/ContactUsPopup';
import FloorPlanPopup, { FloorPlanPopupProps } from '../../popups/floorPlanPopup/FloorPlanPopup';
import data from '../../data.json';
import { ParadeData } from '../../utils/HomeData';
import utils from '../../utils/utils';

const paradeData: ParadeData = data as ParadeData;

export interface HomeDetailsPageProps {
	className: string;
	homeName: string;
}

const HomeDetailsPage: React.FC<HomeDetailsPageProps> = (props) => {
	let home = paradeData.houses[props.homeName];
	let homeAddress = home.stats.address;

	function onLearnMore() {
		popupController.open<ContactUsPopupProps>(ContactUsPopup);
	}

	function showFloorPlan() {
		popupController.open<FloorPlanPopupProps>(FloorPlanPopup, {homeName: props.homeName});
	}

	function showMap() {
		let address: string = JSON.stringify(
			homeAddress.street + ' ' + homeAddress.city + ' ' + homeAddress.state + ' ' + homeAddress.zip
		).replace(' ', '+');
		// window.open('https://www.google.com/maps/dir/?api=1&destination=568+E+130+S+Salem+UT', '_blank');
		window.open(`https://www.google.com/maps/dir/?api=1&destination=${address}`, '_blank');
	}

	function showExterior(homeName: string) {
		appRouter.navigate('/exterior/details', { homeName });
	}

	function showBrowseRooms(homeName: string) {
		appRouter.navigate('/browse/rooms', { homeName });
	}

	function renderStats() {
		return (
			<div className="houseStats">
				<div>
					<FaBath  size={25}/>
					{/*<img alt='' src={BathIcon} />*/}
					<Label xSmall fw500>
						{home.stats.bathrooms}
					</Label>
				</div>
				<div>
					<FaBed size={25} />
					{/*<img alt='' src={RoomIcon} />*/}
					<Label xSmall fw500>
						{home.stats.bedrooms}
					</Label>
				</div>
				<div>
					<FaTape size={25}/>
					{/*<img alt='' src={MeasureIcon} />*/}
					<Label xSmall fw500>
						{utils.numberWithCommas(home.stats.footage)}
					</Label>
				</div>
			</div>
		);
	}

	return (
		<Page className="rsHomeDetailsPage">
			<BsArrowLeftShort className="backButton" onClick={() => appRouter.back('')} />
			{/*<ImageOverlay source={homeDetailsImage} className="homeOverlay" />*/}
			<img
				className="detailsHeaderImage"
				src={utils.getImagePath(props.homeName, 'exterior', home.exterior.images[0])}
				alt="Exterior House"
			/>
			<div className="inset">
				<Label gray className="title" allCaps medSmall center fw500>
					{props.homeName}
				</Label>
				{renderStats()}
				<div
					className="selector"
					onClick={() => {
						showExterior(props.homeName);
					}}
				>
					<Label allCaps small black>
						Home Exterior
					</Label>
					<BsArrowRightShort />
				</div>
				<div
					className="selector blue"
					onClick={() => {
						showBrowseRooms(props.homeName);
					}}
				>
					<Label allCaps small white>
						Browse Rooms
					</Label>
					<BsArrowRightShort />
				</div>
				<Label allCaps fw500 medSmall className="about">
					About
				</Label>
				<Label xSmall>{home.stats.description}</Label>
				{home.stats.video ? (
					<video controls className="houseVideoOverview" poster={utils.getImagePath(props.homeName, 'exterior', home.exterior.images[0])}>
					<source src={utils.getImagePath(props.homeName, 'video', home.stats.video)} type="video/mp4" />
					</video>) : ('')}
				<Label medLarge className="floorPlan" onClick={showFloorPlan}>
					<img alt='' src={FloorPlanIcon} />
					Floor Plan
				</Label>
				<div className="location">
					<MdLocationOn className="locationMarkerIcon" />
					<div>
						<Label fw500>COME AND VISIT</Label>
						<Label xSmall>{homeAddress.street}</Label>
						<Label xSmall>
							{homeAddress.city}, {homeAddress.state} {homeAddress.zip}
						</Label>
					</div>
					<BsArrowRightShort className="nextIcon" onClick={showMap} />
				</div>
			</div>
			<div className="daviesLogo">
				<img alt='' src={daviesLogo} />
				<Label xSmall>Building a Home?</Label>
				<Label xSmall className="learnMoreLink" onClick={onLearnMore}>
					Learn More
				</Label>
			</div>
		</Page>
	);
};
export default HomeDetailsPage;
